
import React, { useState, useEffect } from "react"
import { ArrowSmDownIcon, ArrowSmUpIcon, InformationCircleIcon } from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';

import { readableNumber } from '../../utils/text'
// import { Link } from "gatsby"


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



// if it's a subreddit, fetch stats on the subreddit & display then here.
const SubredditStats = ({
  community, updateMemberCount
}) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);

  useEffect(() => {
    getSubredditDetails();
  }, []);

  const getSubredditDetails = () => {
    if (loading || results) return;

    setLoading(true);
    const API_URL = 'https://gummysearch-api.onrender.com/api/v1/reddit/subreddits/'
    const response = fetch(`${API_URL}${community.name.replace('r/','')}/`, {
      method: "GET",
      headers: {"Authorization": "Token LDqKCZBUmZKhHAFyheQWVxFWu"}
    })
    .then(response => response.json()) // parse JSON from request
    .then(resultData => {
      setResults(resultData)

      // update parent component with the most up to date info
      if (updateMemberCount && resultData && resultData.count_users){
        updateMemberCount(resultData.count_users)
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setLoading(false);
    })
  }

  // don't render anything if we don't successfully fetch results
  if (loading || !results || !results.calculated || !Object.keys(results.calculated).length) return ''
  const stats = results.calculated;
  const tags = results.tags;
  console.log(stats)

  const statsToShow = [
    { 
      name: 'Weekly Growth',
      stat: `${stats.growth_weekly && stats.growth_weekly.toFixed(2)}`,
      tooltip: 'Consolidated growth metric across members, submissions, and comments',
      suffix: '% / week',
      // change: `${stats.growth_weekly.toFixed(2)}% / week`,
      // changeType: 'increase',
      // changeSuffix: '',
      percentile: stats.percentile_growth_weekly,
    },
    { 
      name: 'Total Members',
      stat: stats.current_members,
      suffix: '',
      change: stats.growth_members_daily && readableNumber((stats.current_members * stats.growth_members_daily / 100).toFixed(0)),
      changeType: 'increase',
      changeSuffix: ' / day',
      percentile: stats.percentile_members,
    },
    // { 
    //   name: 'New Submissions',
    //   stat: stats.growth_submissions_daily && readableNumber((stats.current_submissions * stats.growth_submissions_daily / 100).toFixed(0)),
    //   // stat: stats.per_day_submissions && stats.per_day_submissions > 1 ? stats.per_day_submissions.toFixed(0) : stats.per_day_submissions.toFixed(2),
    //   suffix: 'per day',
    //   // change: stats.growth_submissions_weekly.toFixed(2),
    //   // changeType: 'increase',
    //   changeSuffix: '% / week',
    //   percentile: stats.percentile_per_day_submissions,
    // },
    // { 
    //   name: 'New Comments',
    //   stat: stats.growth_comments_daily && readableNumber((stats.current_comments * stats.growth_comments_daily / 100).toFixed(0)),
    //   // stat: stats.per_day_comments && stats.per_day_comments > 1 ? stats.per_day_comments.toFixed(0) : stats.per_day_comments.toFixed(2),
    //   suffix: 'per day',
    //   // change: stats.growth_comments_weekly.toFixed(2),
    //   // changeType: 'increase',
    //   changeSuffix: '% / week',
    //   percentile: stats.percentile_per_day_comments,
    // },
    // { 
    //   name: 'User Engagement',
    //   stat: stats.per_user_comments.toFixed(2),
    //   suffix: 'comments per user',
    //   // change: stats.growth_comments_weekly.toFixed(2),
    //   // changeType: 'increase',
    //   changeSuffix: ' / day',
    //   percentile: stats.percentile_per_user_comments,
    // },
    // { 
    //   name: 'Post Engagement',
    //   stat: stats.comments_per_post.toFixed(2),
    //   suffix: 'comments per post',
    //   // change: '',
    //   // changeType: 'increase',
    //   changeSuffix: '',
    //   percentile: stats.percentile_comments_per_post,
    // },
  ]


  return (
    <div className="rounded-lg bg-white overflow-hidden shadow">
      <div className="px-6 py-4 pb-0">
        <h2 className="text-base font-medium text-gray-900">
          Subreddit Analytics
        </h2>
        <div className="text-xs text-gray-400">
          <a href="https://gummysearch.com/?ref=thehiveindex.com&utm_source=thehiveindex.com" rel="noopener dofollow" target="_blank"
            className="underline cursor-pointer"
          >
            Reddit Audience Research by GummySearch
          </a>
        </div>
      </div>

      <div className="flex flex-wrap items-center px-6 pt-2">
        <ReactTooltip id={`subreddit-tags`} effect="solid" backgroundColor="black" textColor="white" />
                  
        {tags.map((t) => (
          <div key={t.key} 
            className={`cursor-pointer text-xs py-1 px-2 text-xs mr-2 mb-2 bg-gray-100 text-gray-800 rounded-sm`}
            data-for={`subreddit-tags`} data-tip={t.description}
          >
            {t.name}
          </div>
        ))}
      </div>

      <div>
        <ReactTooltip id={`subreddit-stats`} place="right" effect="solid" backgroundColor="black" textColor="white"
          className="w-60 text-xs"
        />
        <dl className="rounded-lg bg-white pb-2">
          {statsToShow.map((item, index) => (
            <div key={item.name} className="p-2 px-6 sm:py-2">
              <dt className="text-sm font-normal text-gray-900 flex items-center">
                <div>{item.name}</div>

                {item.tooltip ? (
                  <div data-for={`subreddit-stats`} data-tip={item.tooltip}>
                    <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer" aria-hidden="true" />
                  </div>
                ) : ''}
    
              </dt>
              <dd className="flex items-center">
                {true ? (
                  <div className="flex items-baseline text-base font-semibold text-gray-600">
                    {readableNumber(item.stat)}
                    <span className="ml-1 text-xs font-medium text-gray-500">{item.suffix}</span>
                  </div>
                ) : ''}

                {item.change ? (
                  <div
                    className={`${item.stat ? 'ml-1' : ''} bg-green-100 text-green-800 inline-flex px-2 py-0.5 rounded-full text-xs font-medium flex items-center`}
                  >
                    <ArrowSmUpIcon
                      className="h-4 w-4 text-green-500"
                      aria-hidden="true"
                    />

                    <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                    {item.change}{item.changeSuffix}
                    
                  </div>
                ) : ''}

                {item.percentile ? (
                  <div
                    data-for={`subreddit-stats`} data-tip={`Percentile value, how this Subreddit stacks up against all others on Reddit`}
                    className={'ml-auto bg-gray-100 text-gray-800 inline-flex px-2 py-0.5 rounded-full text-xs font-medium flex items-center'}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <div>{item.percentile}%</div>
                  </div>
                ) : ''}

                
              </dd>
            </div>
          ))}
        </dl>
        <div className="text-xs text-gray-400 px-6 mb-4">
          <a href={`https://share.gummysearch.com/subreddit/r-${community.name.replace('r/','')}/?ref=thehiveindex.com&utm_source=thehiveindex.com`} rel="noopener dofollow" target="_blank"
            className="underline cursor-pointer"
          >
            More analysis of {community.name}
          </a>
        </div>
      </div>
    </div>
  )
}


export default SubredditStats
