import React, { useState } from "react"
import { Link } from "gatsby"

import {
  UserGroupIcon,
  ClipboardListIcon,
  TagIcon,
} from '@heroicons/react/outline'

import { unslugify, readableNumber } from '../../utils/text'

import CommunityStarRating from "./starrating"

import { sortCommunities } from '../../utils/communities'
// import CommunityFeatureTags from "../feature/communityfeaturetags"

// import "../styles/communitiestable.css"

const SimilarCommunities = ({
  communities, 
  hideTopPicks,
  hideRatings,
}) => {
  const defaultIncrement = 5;
  const [countToShow, setCountToShow] = useState(defaultIncrement);  // user expands manually
  
  // sort by rating, then move the promoted ones up to the top
  const communitiesToShow = sortCommunities("Ratings", communities)
    .sort((a,b) => (a.promoted < b.promoted) ? 1 : ((b.promoted < a.promoted) ? -1 : 0))
    .sort((a,b) => (a.gold < b.gold) ? 1 : ((b.gold < a.gold) ? -1 : 0))
    .slice(0, countToShow);

  // const communitiesToShow = communities
  //   .sort((a,b) => (a.promoted < b.promoted) ? 1 : ((b.promoted < a.promoted) ? -1 : 0))
  //   .slice(0, countToShow);
  
  return (
    <React.Fragment>
      <ul className="divide-y divide-gray-200">
        {communitiesToShow.map((c, index) => (
          <li key={c.slug}>
            <Link to={`/communities/${c.slug}/`} className="block hover:bg-gray-50 p-2 py-3 flex">
              {c.gold && c.logo && !hideTopPicks ? (
                <img className="flex-shrink-0 h-20 w-20 mr-4 object-contain rounded-md object-left sm:object-center" src={c.logo} alt={`${c.name} logo`} width="70" height="42" loading="lazy" />
              ) : ''}

              <div>
                <div className="text-sm items-center">
                  <p className="font-medium text-gray-700 truncate">{c.name}</p>
                  {/*{c.platform && c.platform !== 'independent' ? (
                    <p className="ml-1 flex-shrink-0 font-normal text-gray-400"> on {c.platform}</p>
                  ) : ''}*/}

                  {false && !hideTopPicks && c.promoted ? (
                    <div className="ml-auto py-0.5 px-1 mb-0.5 bg-yellow-400 rounded-sm text-xs text-white flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                      </svg>
                      <div>Featured</div>
                    </div>
                  ) : !hideRatings && c.reviewCount !== null && c.reviewCount !== undefined && c.reviewCount !== "0" ? (
                    <div className="mt-0 flex items-center text-xs">
                      <CommunityStarRating community={c} starHeight={4}/>
                    </div>
                  ) : ''}
                </div>
                {/*{c.reviewCount !== null && c.reviewCount !== undefined && c.reviewCount !== "0" ? (
                  <div className="flex items-center text-sm mt-1">
                    <CommunityStarRating community={c} />
                  </div>
                ) : ''}*/}
                {c.description ? (
                  <div className={`mt-1 text-xs text-gray-500 ${c.gold && !hideTopPicks ? 'line-clamp-3' : 'line-clamp-2'}`}>
                    {c.description}
                  </div>
                ) : ''}
              </div>
            </Link>
          </li>
        ))}
      </ul>
      {communitiesToShow.length < communities.length ? (
        <div className="p-4 text-center text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer"
          onClick={() => setCountToShow(countToShow + defaultIncrement)}
        >
          {communities.length - communitiesToShow.length} more {communities.length - communitiesToShow.length === 1 ? 'community' : 'communities'} →
        </div>
      ) : ''}
    </React.Fragment>
  )

}


export default SimilarCommunities