import React from "react"

import TopicCard from "./topiccard"

// lists a bunch of topics
const TopicList = ({topics, mini, cols, hideImage, gridClass}) => {

  const columns = cols || 4;
    
  return (
    <ul className={`mt-2 grid ${gridClass || `grid-cols-1 sm:grid-cols-2 lg:grid-cols-${columns}`} gap-x-4 ${mini ? 'gap-y-6' : 'gap-y-8'} sm:gap-x-6 xl:gap-x-8`}>
      {topics.map(t=>(
        <TopicCard key={t.slug} topic={t} mini={mini} hideImage={hideImage}/>
      ))}
    </ul>
  )
}

export default TopicList;

