const unslugify = (slug) => {
  if (!slug) return '';
	return slug.split('-').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
}

// converts 10000000 into 10M
const readableNumber = (count) => {
	const mil = 1000000;
	const thou = 1000;

	if (count >= mil){
		return `${(count / mil).toFixed(1)}M`
	} else if (count >= thou){
		return `${(count / thou).toFixed(0)}K`
	}
	return count
}


const capitalizeFirst = (word) => {
    if (!word) return ''
    return word[0].toUpperCase() + word.slice(1, word.length)
}

const convertToSlug = (t) => t.toLowerCase().replace(/ /g, "-").replace(/[^\w-]+/g, "");

export {
    unslugify,
    readableNumber,
    capitalizeFirst,
    convertToSlug,
}

