import React from "react"

import { trackEvent } from "../../utils/tracking"


// partner deals
const Deals = ({
  deals,
  topic, // either topic or community, not both right now
  community, // either topic or community, not both right now
}) => {

  const onRedirect = (deal) => {
    // // track event
    trackEvent('DealClick', {
      'order': deal.order,
      'title': deal.title,
      'location': topic ? 'topic' : community ? 'community' : 'unknown',
      'topic': topic ? topic.slug : '',
      'community': community ? community.slug : '',
      'url': deal.url,
    })
  }

  const getDealUrl = (deal) => {
    var toReturn = deal.url;
    if (toReturn.includes('?')){
      toReturn += '&utm_source=thehiveindex.com';
    } else {
      toReturn += '?utm_source=thehiveindex.com'
    }
    return toReturn;
  }
  
  return (
    <section aria-labelledby="promoted-deals">
      <div className="rounded-lg bg-white overflow-hidden shadow p-6">
        {topic ? (
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex items-center justify-between">
              <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">
                Offers
              </h2>
            </div>
          </div>
        ) : community ? (
          <div className="relative flex items-center">
            <h2 className="text-base font-medium text-gray-900 bg-white">
              Offers
            </h2>
          </div>
        ) : 'invalid'}

        <div className={`mt-2 space-y-4`}>          
          {deals.map(deal => (
            <div key={deal.name}>
              <div className="flex">
                {deal.logo ? (
                  <a href={getDealUrl(deal)} target="_blank" className="mr-3 h-12 w-12 flex-shrink-0"
                    rel="sponsored nofollow"
                    onClick={() => onRedirect(deal)}
                  >
                    <img src={deal.logo} className="h-12 w-12 rounded-md mr-3 object-contain" />
                  </a>
                ) : ''}

                <div>
                  <a href={getDealUrl(deal)} target="_blank" className={`block cursor-pointer font-medium text-rose-500 hover:underline`}
                    rel="sponsored nofollow"
                    onClick={() => onRedirect(deal)}
                  >
                    {deal.title}
                  </a>

                  <div className="mt-0 block text-sm text-gray-500">
                    {deal.subtitle}
                  </div>
                </div>
              </div>
                
              {deal.offer ? (
                <div className="mt-1">
                  <a href={getDealUrl(deal)} target="_blank" className="mt-2 font-medium text-sm text-green-500 hover:underline"
                    rel="sponsored nofollow"
                    onClick={() => onRedirect(deal)}
                  >
                    {deal.offer} →
                  </a>
                </div>
              ) : ''}
            </div>
          ))}

        </div>
      </div>
    </section>
  )
}

export default Deals;

