import React, { useState } from "react"
import { Link } from "gatsby"

const CommunityStats = ({community}) => {

  const statsToShow = [
    { 
      name: 'Visitors',
      stat: community.visitors,
    },
    { 
      name: 'Clicks',
      stat: community.redirects,
    },
    // { 
    //   name: 'Pageviews',
    //   stat: community.views,
    // },
  ]

  return (
    <section aria-labelledby="community-stats">
      <div className="rounded-lg bg-white overflow-hidden shadow p-6">
        <div className="relative flex items-center">
          <h2 className="text-base font-medium text-gray-900 bg-white">
            Monthly Stats
          </h2>
          {/*<span className="ml-1 flex-shrink-0 text-xs text-gray-500 bg-white px-1 pr-2">
            <span>Monthly</span>
          </span>*/}
          <Link to={'/partners/community/'} 
            className="ml-auto hidden sm:inline-block">
            <button
              type="button"
              className="inline-flex items-center shadow-sm px-2 py-1 border border-gray-300 text-xs font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
            >
              <span>Promote</span>
            </button>
          </Link>
        </div>

        <div className={`mt-2`}>
          <dl className="rounded-lg w-full sm:flex">
            {statsToShow.map((item, index) => (
              <div key={item.name} className="p-2 flex-1 text-center">
                <dd className="ml-auto items-baseline text-lg font-semibold text-rose-500">
                  {item.stat}
                </dd>
                <dt className="text-sm font-normal text-gray-600">
                  <div>{item.name}</div>
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  )
}

export default CommunityStats;

