import React from "react"

import {
  QuestionMarkCircleIcon,
  SpeakerphoneIcon,
  ChatIcon,
  CurrencyDollarIcon,
  PencilAltIcon,
  LinkIcon,
  GiftIcon,
  CalendarIcon,
  NewspaperIcon,
  BriefcaseIcon,
  AcademicCapIcon,
  CubeTransparentIcon,
  QrcodeIcon,
  TableIcon,
  BookmarkAltIcon
} from '@heroicons/react/outline'

// tags for features
const FeatureIcon = ({feature, className}) => {

  return (
    <span className={className}>
      {feature === 'forum' ? (
        <SpeakerphoneIcon />
      ) : feature === 'chat' ? (
        <ChatIcon />
      ) : feature === 'apply' ? (
        <PencilAltIcon />
      ) : feature === 'pairing' ? (
        <LinkIcon />
      ) : feature === 'perks' ? (
        <GiftIcon />
      ) : feature === 'events' ? (
        <CalendarIcon />
      ) : feature === 'newsletter' ? (
        <NewspaperIcon />
      ) : feature === 'jobs' ? (
        <BriefcaseIcon />
      ) : feature === 'paid' ? (
        <CurrencyDollarIcon />
      ) : feature === 'courses' ? (
        <AcademicCapIcon />
      ) : feature === 'tools' ? (
        <BookmarkAltIcon />
      ) : feature === 'token' ? (
        <QrcodeIcon />
      ) : (
        <QuestionMarkCircleIcon/>
      )}
    </span>
  )
}

export default FeatureIcon;

