const sortCommunities = (sort, filteredCommunities) => {
  // sort them
  if (sort === "Largest"){
    filteredCommunities = filteredCommunities.sort((a,b) => (a.members < b.members) ? 1 : ((b.members < a.members) ? -1 : 0)); 
  } else if (sort === "Popular"){
    // first sort by feature count (in case this is a new topic)
    filteredCommunities = filteredCommunities.sort((a,b) => (a.countFeatures < b.countFeatures) ? 1 : ((b.countFeatures < a.countFeatures) ? -1 : 0)); 

    // real sort
    filteredCommunities = filteredCommunities.sort((a,b) => (a.redirects < b.redirects) ? 1 : ((b.redirects < a.redirects) ? -1 : 0));
  } else if (sort === "Ratings"){
    // sort first by count features, then by total number of stars (from reviews) if they have them
    filteredCommunities = filteredCommunities.sort((a,b) => (a.countFeatures < b.countFeatures) ? 1 : ((b.countFeatures < a.countFeatures) ? -1 : 0)); 
    filteredCommunities = filteredCommunities.map(c => Object.assign(c, {'stars': c.reviewCount * c.reviewAvg})).sort((a,b) => (a.stars < b.stars) ? 1 : ((b.stars < a.stars) ? -1 : 0)); 
  
    // low-rated communities go to bottom
    filteredCommunities = [
      ...filteredCommunities.filter(c => c.reviewCount === "0" || parseInt(c.reviewAvg) >= 3),
      ...filteredCommunities.filter(c => c.reviewCount !== "0" && parseInt(c.reviewAvg) < 3),
    ]
  } else if (sort === "Features"){
    // not used
    filteredCommunities = filteredCommunities.sort((a,b) => (a.countFeatures < b.countFeatures) ? 1 : ((b.countFeatures < a.countFeatures) ? -1 : 0)); 
  } else if (!sort || sort === "Newest"){
    filteredCommunities = filteredCommunities.sort((a,b) => (a.order < b.order) ? 1 : ((b.order < a.order) ? -1 : 0));
  } else if (!sort || sort === "Oldest"){
    filteredCommunities = filteredCommunities.sort((a,b) => (a.order < b.order) ? -1 : ((b.order < a.order) ? 1 : 0));
  }
  return filteredCommunities
}

export {
    sortCommunities,
}

