
import React, { useState } from "react"
import { PlusSmIcon } from '@heroicons/react/solid'

import CommunityStarRating from "./starrating"
// import { Link } from "gatsby"

// import {
//   UserGroupIcon,
//   ClipboardListIcon,
//   TagIcon,
// } from '@heroicons/react/outline'

import { trackEvent } from "../../utils/tracking"


const CommunityReviews = ({
  community, 
  reviews,
}) => {

  const defaultIncrement = 3;
  const [countToShow, setCountToShow] = useState(defaultIncrement);  // user expands manually
  const reviewsToShow = reviews
    .sort((a,b) => (a.pinned < b.pinned) ? 1 : ((b.pinned < a.pinned) ? -1 : 0))
    .slice(0, countToShow);

  const onRedirect = () => {
    // track event
    trackEvent('ReviewInit', {
      'event_label': community.name,
      'type': 'community',
      'community': community.slug,
      'platform': community.platform,
      'topics': community.topics,
    })
  }

  // generic non-prefilled url
  var formUrl = `https://docs.google.com/forms/d/e/1FAIpQLSf4kMCKJDvmgXG1t3YiYiiu_VmA61FCEhldjKVrEWiG6w8tdA/viewform?usp=pp_url`
  formUrl += `&entry.1769587253=${community.slug}` // add community slug as prefilled

  return (
    <section aria-labelledby="community-reviews">
      <div className="">
          
        <div className="relative pb-0">
          {/*<div className="absolute inset-0 flex items-center p-6 pb-0" aria-hidden="true">
            <div className="w-full border-t border-gray-200" />
          </div>*/}
          <div className="relative flex items-center">
            <h2 className="text-base font-medium text-gray-900 bg-white">
              Community Reviews
            </h2>
            {reviews && reviews.length ? (
              <React.Fragment>
                <span className="ml-2 flex-shrink-0 font-semibold text-xs text-gray-500 bg-white px-1 pr-2">
                  <span>{reviews.length}</span>
                </span>
                <a href={formUrl} target="_blank" rel="noopener" onClick={onRedirect}
                  className="ml-auto hidden sm:inline-block">
                  <button
                    type="button"
                    className="inline-flex items-center shadow-sm px-2 py-1 border border-gray-300 text-xs font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                  >
                    <PlusSmIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span>Add</span>
                  </button>
                </a>
              </React.Fragment>
            ) : ''}
          </div>

          {/*<div className="mx-auto text-center w-full">
            {reviews && reviews.length && community.reviewCount !== null ? (
              <CommunityStarRating community={community} />
            ) : ''}
          </div>*/}
        </div>


        <div className="p-0">
          {reviews && reviews.length ? (
            <div className="mb-2 divide-y divide-gray-200">
              {reviewsToShow.map(r => (
                <div key={`${r.yourFirstName}-${r.timestamp}`} className="py-3 text-sm">
                  <div className="text-sm text-gray-600">
                    <span className="text-left text-gray-400">“</span>
                    <span>{r.publicReview}</span>
                    <span className="text-right text-gray-400">“</span>
                  </div>
                  <div className="flex items-center mt-1">
                    

                    <div className="mr-2 bold text-sm">- {r.yourFirstName}</div>
                    {["Owner/Administrator"].includes(r.yourRelationshipToTheCommunity) ? (
                      <div className="mr-2 bg-gray-200 text-gray-500 text-xs rounded-sm px-1 py-0.5">{r.yourRelationshipToTheCommunity}</div>
                    ) : ''}

                    <div className="ml-auto flex items-center space-x-1">
                      {[...Array(parseInt(r.overall)).keys()].map((s, index) => (
                        <svg key={s} xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-yellow-400" fill="#fef3c7" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                        </svg>
                      ))}
                      {[...Array(5 - parseInt(r.overall)).keys()].map((s, index) => (
                        <svg key={s} xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-200" fill="#fff" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : ''}

          {reviewsToShow.length < reviews.length ? (
            <div className="p-4 text-center text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer"
              onClick={() => setCountToShow(countToShow + defaultIncrement)}
            >
              {reviews.length - reviewsToShow.length} more {reviews.length - reviewsToShow.length === 1 ? 'review' : 'reviews'} →
            </div>
          ) : ''}

          {!reviews.length ? (
            <a href={formUrl} target="_blank" rel="noopener" onClick={onRedirect}>
              <div className="border-dashed border-2 border-gray-200 hover:border-gray-300 rounded-md p-4 m-2 text-center">
                <div className="text-sm font-medium text-gray-400">No reviews for {community.name}...</div>
                
                <div className="flex my-2 mx-auto justify-center">
                  {[1,2,3,4,5].map((s, index) => (
                    <svg key={s} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-1 text-gray-300 hover:text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                    </svg>
                  ))}
                </div>
                <div className="text-xs text-gray-400">Would you like to submit one?</div>
              </div>
            </a>
          ) : ''}          

        </div>
      </div>
    </section>
  )

}


export default CommunityReviews