import React,  { useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import TopicList from "../components/topic/topiclist"
import CommunityFeatures from "../components/feature/communityfeatures"
import SimilarCommunities from "../components/community/similarcommunities"
import CommunityReviews from "../components/community/reviews"
import SubredditStats from "../components/community/subredditStats"
import CommunityStats from "../components/community/stats"
import ShareButtons from "../components/common/sharebuttons"
// import Adsense from "../components/promotions/adsense"
import Deals from "../components/promotions/deals";

import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

import { readableNumber, capitalizeFirst } from '../utils/text'
import { average } from '../utils/numbers'

// clean these up below
import {
  UserGroupIcon,
  FlagIcon
} from '@heroicons/react/outline'


const CommunityPage = ({ data, classes, pageContext }) => {
  const community = data.community;
  const platform = data.platform;
  const topics = data.topics.nodes;
  const similarCommunities = data.similarCommunities.nodes;
  const reviews = data.reviews.nodes;
  const [updatedMemberCount, setUpdatedMemberCount] = useState(null);

  // keeping this here for now b/c we track certain parameters about the current url
  const clickedLink = () => {
    if (typeof(window.gtag) !== "undefined"){
      window.gtag('event', 'Redirect', {
        'event_label': community.name
      });
    }
  }

  // make the description
  var generatedDescription = `${community.name} is`;
  if (platform && platform.slug !== 'independent'){
    generatedDescription += ` a ${platform.groupName}`
  } else {
    generatedDescription += ` an online community`
  }
  if (topics.length){
    generatedDescription += ` for `
    var communityTopics = topics.map((t, i) => t.people ? t.people : t.name);
    if (communityTopics.length > 1){communityTopics[communityTopics.length - 1] = `and ${communityTopics[communityTopics.length - 1]}`}
    if (communityTopics.length === 2){
      generatedDescription += communityTopics.join(' ')
    } else {
      generatedDescription += communityTopics.join(', ')
    }
  }
  if (community.members){
    generatedDescription += ` with roughly ${readableNumber(updatedMemberCount || community.members)} members`
  }
  generatedDescription += `.`;

  // add communication style
  if (community.forum && community.chat){
    generatedDescription += ' It has both a forum and a live chat communication style.'
  } else if (community.forum){
    generatedDescription += ' It uses a forum format for communication.'
  } else if (community.chat){
    generatedDescription += ' It has a live chat communication style.'
  }
  // TODO: expand with more feature text.

  // url we redirect to is the community url. construct the internal one too for tracking
  const linkUrl = `${community.url}`;
  const status = community.sponsored ? 'sponsored' : community.promoted ? 'promoted' : community.gold ? 'gold' : community.affiliate ? 'affiliate' : 'free';
  var internalLinkUrl = `/redirect/?url=${encodeURIComponent(linkUrl)}&name=${encodeURIComponent(community.name)}&slug=${community.slug}&topics=${community.topics}&platform=${community.platform}&status=${status}`

  // this should be just the host really
  const linkDisplay = linkUrl.replace('www.', '').replace(`https://`, '').replace(`http://`, '').split('?')[0];

  // review stuff
  const ratingValue = String(parseInt(average(reviews.map(r => r.overall))));
  const ratingCount = String(reviews.length);
  const reviewSnippet = reviews.length ? `${[...Array(parseInt(ratingValue)).keys()].map((s) => '⭐️').join('')} (${ratingCount}) ` : ''

  // open graph image
  const logoUrl = community.logo || (platform && platform.logoBig)
  const metaSubtitle = `${platform && platform.slug !== 'independent' ? `${platform.groupName}` : 'Online Community'} for ${community.topics.split(',').map(t => capitalizeFirst(t)).join(', ')}`
  const seoImageUrl = `https://og.tailgraph.com/og?fontFamily=Open%2BSans&title=${encodeURIComponent(community.name)}&titleTailwind=font-bold%20text-6xl%20text-rose-600&text=${encodeURIComponent(metaSubtitle)}&textTailwind=text-2xl%20mt-4%20text-yellow-600&textFontFamily=Source%2BSans%2BPro&logoUrl=${logoUrl}&logoTailwind=mx-auto%20h-48%20mb-4&bgUrl=https%3A%2F%2Fthehiveindex.com%2Fstatic%2F6f9a721ed54860c63211350929d004b7%2F9c94d%2Ficon.png&bgTailwind=bg-white&overlay=1&overlayTailwind=bg-white%20opacity-95&footer=thehiveindex.com&footerTailwind=font-bold%20text-rose-500%20text-center%20text-xl&containerTailwind=bg-transparent`

  // determines if we show ads to other communities, as well as our deals
  const hideAds = community.promoted || community.gold;

  // get the deals (nodes are all current deals, this filters down to unique ones that overlap on topics)
  var uniqueDeals = []
  data.deals.nodes.filter(d => community.topics.split(',').includes(d.topic)).forEach(d => {
    if (!uniqueDeals.find(d2 => d2.name === d.name)){
      uniqueDeals.push(d)
    }
  })

  // contents. either rendered in layout or a modal
  const communityContents = (
    <React.Fragment>
      <SEO
        title={`${community.name} - ${metaSubtitle}`}
        description={`${reviewSnippet}${generatedDescription} ${community.description ? community.description : ''}`}
        imageUrl={seoImageUrl}
        // imageSize={'small'}
        generateImage={false}
        path={`/communities/${community.slug}/`}
        schema={!reviews.length ? null : {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": community.name,
          "description": generatedDescription,
          "url": `https://thehiveindex.com/communities/${community.slug}/`,
          "image": {
            "@type": "ImageObject",
            "url": community.logo || (platform && platform.logoBig),
            "contentUrl": community.logo || (platform && platform.logoBig)
          },
          "brand": {
            "@type":"Brand",
            "name": community.name,
            "logo": {
              "@type": "ImageObject",
              "url": community.logo || (platform && platform.logoBig),
              "contentUrl": community.logo || (platform && platform.logoBig)
            }
          },
          "subjectOf": {
            "@type": "Product",
            "name": community.name,
            "aggregateRating": {
              "@type":"AggregateRating",
              "@id": "review",
              "ratingValue": ratingValue,
              "ratingCount": ratingCount,
              "bestRating": "5",
              "worstRating": "0",
            },
            "review": reviews.map((r) => {
              return {
                "@type": "Review",
                "name": r.yourFirstName,
                "reviewBody": r.publicReview,
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": String(r.overall),
                  "bestRating": "5",
                  "worstRating": "0"
                },
                "author": {"@type": "Person", "name": r.yourFirstName}
              }
            }),
          }
        }}
      />

      {/* Main 3 column grid */}
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        {/* Left column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          {/* Welcome panel */}
          <section aria-labelledby="profile-overview-title">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="sr-only" id="community-overview-title">
                Community Overview
              </div>
              <div className="bg-white p-6">
                <div className="sm:flex sm:items-center sm:justify-between flex-wrap">
                  <div className="sm:flex sm:space-x-5 mr-2 items-center">
                    <div className="flex-shrink-0">
                      {community.logo ? (
                        <img className="mx-auto h-20 w-20 rounded-md object-contain" src={community.logo} alt={community.name} />
                      ) : ''}
                    </div>
                    {/*<div className="text-center sm:mt-0 sm:text-left">
                      <h1 className="text-xl font-bold text-gray-900 sm:text-2xl mt-0">{community.name}</h1>
                    </div>*/}
                  </div>

                  {community.url ? (
                    <div className="mt-5 flex justify-center flex-shrink-0 sm:mt-0 ml-auto">
                      <Link to={internalLinkUrl} onClick={clickedLink}
                        className="flex justify-center items-center px-4 py-2 border border-rose-600 text-white bg-rose-500 hover:bg-rose-600 shadow-sm text-sm rounded-sm text-white"
                      >
                        <div className="mr-4">
                          <div className="opacity-100 font-medium max-w-xs truncate">{linkDisplay}</div>
                          <div className="opacity-75 text-xs font-semibold">Go to community</div>
                        </div>
                        <div>
                          →
                        </div>
                      </Link>
                    </div>
                  ) : ''}
                </div>

                <h2 className="text-base font-medium text-gray-900 mb-1 mt-4" id="own-words-title">
                  {community.name.toLowerCase().includes('community') ? (
                    <span>About {community.name}</span>
                  ) : (
                    <span>About the {community.name} community</span>
                  )}
                </h2>
                <p className="text-sm text-gray-500">{generatedDescription}</p>

                {community.description && (
                  <div className="mt-2">
                    <h2 className="text-base font-medium text-gray-900 mb-1" id="own-words-title">
                      In their own words
                    </h2>
                    <p style={{whiteSpace: 'pre-wrap'}} className="text-sm text-gray-500">
                      {community.description}
                    </p>
                  </div>
                )}
              </div>
              <div className="border-t border-gray-200 text-gray-600 bg-gray-50 flex flex-wrap divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
                { platform && platform.slug !== 'independent' ? (
                  <Link to={`/platforms/${platform.slug}/`} className="underline flex-1 px-6 py-5 text-sm font-medium text-center hover:text-gray-800">
                    <span>On</span>{' '}
                    <span>{platform.name}</span>
                  </Link>
                ) : community.platform && community.platform.includes(',') ? (
                  <div className="flex-1 px-6 py-5 text-sm font-medium text-center flex space-x-2 items-middle">
                    <span>Platforms:</span>
                    {community.platform.split(',').map(p => (
                      <Link to={`/platforms/${p}/`} className="underline hover:text-gray-800">
                        <span>{capitalizeFirst(p)}</span>
                      </Link>
                    ))}
                  </div>
                ) : ''}
                {community.established ? (
                  <div className="flex-1 flex px-6 py-5 text-sm font-medium text-center items-center justify-center space-x-1">
                    <FlagIcon className="h-4 w-4 opacity-50"/>
                    <span>Established</span>{' '}
                    <span>{community.established}</span>
                  </div>
                ) : ''}
                {community.members ? (
                  <div className="flex-1 flex px-6 py-5 text-sm font-medium text-center items-center justify-center space-x-1">
                    <UserGroupIcon className="h-4 w-4 opacity-50"/>
                    <span>{readableNumber(updatedMemberCount || community.members)}</span>{' '}
                    <span>Members</span>
                  </div>
                ) : ''}
              </div>
            </div>
          </section>

          {/* details panel */}
          <div className="rounded-lg bg-white overflow-hidden shadow p-6">
            
            {reviews.length > 0 ? (
              <div className="mb-4">
                <CommunityReviews community={community} reviews={reviews} />
              </div>
            ) : ''}
            

            <div className="mb-8">
              <h2 className="text-base font-medium text-gray-900 mb-2" id="features-title">
                Community Topics
              </h2>
              <TopicList topics={topics} mini={true} cols={3}/>
            </div>

            

            <div className="mb-4">
              <h2 className="text-base font-medium text-gray-900 mb-2" id="features-title">
                Community Features
              </h2>
              <CommunityFeatures community={community} />
            </div>
            
          </div>

          <div className="mt-0 text-xs flex items-center space-x-4">
            <Link to="/suggest/" className="text-gray-500 opacity-75 hover:opacity-100 hover:underline flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
              <span className="font-semibold">Suggest an edit</span>
            </Link>
            <a href={`mailto:contact@thehiveindex.com?subject=Report Community: ${community.name}`} className="text-gray-500 opacity-75 hover:opacity-100 hover:underline flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" />
              </svg>
              <span className="font-semibold">Report community</span>
            </a>
            {!community.claimed ? (
              <a href="mailto:contact@thehiveindex.com?subject=Claim Community" className="text-gray-500 opacity-75 hover:opacity-100 hover:underline flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-4 w-4 mr-1">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002" />
                </svg>

                <span className="font-semibold">Claim community</span>
              </a>
            ) : ''}
            
          </div>
        </div>


        {/* Right column */}
        <div className="grid grid-cols-1 gap-4">
          
          {/*{!community.gold && !community.promoted ? (
            <div className="rounded-lg bg-white overflow-hidden shadow p-6 h-72">
              <Adsense id="5516661691" type="community" />
            </div>
          ) : ''}*/}


          {reviews.length === 0 ? (
            <div className="rounded-lg bg-white overflow-hidden shadow p-4 px-5">
              <CommunityReviews community={community} reviews={reviews} />
            </div>
          ) : ''}

          {!hideAds && uniqueDeals && uniqueDeals.length ? (
            <Deals community={community} deals={uniqueDeals} />
          ) : ''}

          {similarCommunities && similarCommunities.length ? (
            <section aria-labelledby="recent-hires-title">
              <div className="rounded-lg bg-white overflow-hidden shadow">
                <div className="flex items-center p-6 pb-0">
                  <h2 className="text-base font-medium text-gray-900">
                    Similar Communities
                  </h2>
                  <span className="ml-2 flex-shrink-0 font-semibold text-xs text-gray-500 bg-white px-1 pr-2">
                    <span>{similarCommunities.length}</span>
                  </span>
                </div>
                <div className="p-4 pt-0">
                  <SimilarCommunities communities={similarCommunities}
                    // hideTopPicks={community.promoted}
                    hideTopPicks={hideAds}
                    hideRatings={hideAds}
                  />
                </div>
              </div>
            </section>
          ) : ''}

          <CommunityStats community={community} />

          {/*{community.platform === 'reddit' ? (
            <SubredditStats community={community} updateMemberCount={setUpdatedMemberCount}/>
          ) : ''}*/}

          

        </div>
      </div>
    </React.Fragment>
  )

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <React.Fragment>
          { modal ? (
            <React.Fragment>
              <div className="flex flex-wrap items-center mb-4 pb-2 border-b">
                {closeTo ? (
                  <Link to={closeTo} className="text-gray-600 hover:text-gray-800 cursor-pointer flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>
                    <div className="ml-2">Close</div>
                  </Link>
                ) : ''}
                <div className="ml-4 sm:ml-8 mr-auto">
                  <h1 className="text-lg sm:text-3xl font-bold text-gray-800">{community.name}</h1>
                </div>
                <div className="hidden sm:inline-block">
                  <ShareButtons title={community.name} buttonBackground="bg-gray-400"/>
                </div>
              </div>
              <div className="">
                {communityContents}
              </div>
            </React.Fragment>
          ) : (
            <Layout pageTitle={community.name} hidePageTitle={true} noBackground={true} enableShare={true} >
              {communityContents}
            </Layout>
          )}
        </React.Fragment>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export const communityPageQuery = graphql`
  query CommunityPage($slug: String, $topicSlugs: [String], $platformSlug: String, $similarCommunitySlugs: [String]) {
    site {
      siteMetadata {
        title
      }
    }
    community: googleSpreadsheetSourceCommunities(slug: {eq: $slug}) {
      name
      slug
      members
      established
      description
      platform
      topics
      logo
      platformLogo
      promoted
      sponsored
      url
      forum
      chat
      apply
      pairing
      events
      perks
      jobs
      newsletter
      paid
      courses
      tools
      token
      gold
      affiliate
      reviewCount
      reviewAvg
      claimed
      visitors
      views
      redirects
    }
    similarCommunities: allGoogleSpreadsheetSourceCommunities(filter: {slug: {in: $similarCommunitySlugs}}) {
      nodes {
        name
        slug
        members
        established
        description
        platform
        logo
        platformLogo
        url
        promoted
        sponsored
        gold
        forum
        chat
        apply
        pairing
        events
        perks
        jobs
        newsletter
        paid
        reviewCount
        reviewAvg
      }
    }
    platform: googleSpreadsheetSourcePlatforms(slug: {eq: $platformSlug}) {
      name
      slug
      logoBig
      description
      groupName
      groupNamePlural
    }
    topics: allGoogleSpreadsheetSourceTopics(filter: {slug: {in: $topicSlugs}}) {
      nodes {
        slug
        name
        count
        image
        people
      }
    }
    reviews: allGoogleSpreadsheetSourceReviews(filter: {community: {eq: $slug}, approved: {eq: true}}, sort: {fields: timestamp, order: DESC}) {
      nodes {
        approved
        pinned
        community
        yourFirstName
        overall
        publicReview
        timestamp
        yourRelationshipToTheCommunity
      }
    }
    deals: allGoogleSpreadsheetSourceDeals(filter: {
      active: {eq: "TRUE"}
    }) {
      nodes {
        order
        title
        subtitle
        topic
        offer
        logo
        url
        order
      }
    }
  }
`

export default CommunityPage

