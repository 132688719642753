import React, { useState } from "react"
import { Link } from "gatsby"

import FeatureIcon from "./featureicon"

import { unslugify } from '../../utils/text'
import { classNames } from "../../utils/tailwind"

// show a community's feature tags
const CommunityFeatures = ({community}) => {
  // const isNotMobile = (typeof window == 'undefined') || (window && window.screen.width >= 600);
  const [showAll, setShowAll] = useState(false);

  const allPossible = [
    'forum', 'chat', 'apply', 'pairing', 'perks', 'events', 'newsletter', 'jobs', 'paid', 'courses', 'tools', 'token'
  ]
  const features = allPossible.map((f) => {
    return {
      'slug': f,
      'has': community[f] ? true : false,
    }
  });

  // const featuresToShow = (isNotMobile || showAll) ? features : features.filter(f => f.has ? true : false);

  const featureText = (feature, has) => {
    if (feature === 'forum'){
      return has ? "This community has a forum" : "This community does not have a forum";
    } else if (feature === 'chat'){
      return has ? "This community has a realtime chat" : "This community does not have a realtime chat";
    } else if (feature === 'apply'){
      return has ? "This community requires an application to join" : "This community does not require an application to join";
    } else if (feature === 'pairing'){
      return has ? "This community sets up 1:1 pairing between its members" : "This community does not set up 1:1 pairing between its members";
    } else if (feature === 'perks'){
      return has ? "This community has discounts and perks for its members (potentially for a cost)" : "This community does not have discounts and perks for its members";
    } else if (feature === 'events'){
      return has ? "This community hosts offline events and meetups" : "This community does not host offline events and meetups";
    } else if (feature === 'newsletter'){
      return has ? "This community has an email newsletter of related content" : "This community does not have an associated newsletter";
    } else if (feature === 'jobs'){
      return has ? "This community has a job board or provides career networking opportunities" : "This community does not have an associated job board";
    } else if (feature === 'paid'){
      return has ? "This community has a paid membership level" : "This community does not require a paid membership";
    } else if (feature === 'courses'){
      return has ? "This community offers educational courses" : "This community does not offer educational courses";
    } else if (feature === 'tools'){
      return has ? "This community aggregates tools & resources" : "This community does not aggregate tools & resources";
    } else if (feature === 'token'){
      return has ? "This community has a crypto token" : "This community does not have a crypto token";
    }
  }

  return (
    <React.Fragment>
      <div className="sm:grid sm:grid-cols-3 sm:gap-px">
        {features.map((feature, featureIdx) => (
          <div
            key={feature.slug}
            className={classNames(
              featureIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              featureIdx === 1 ? 'sm:rounded-tr-lg' : '',
              featureIdx === features.length - 2 ? 'sm:rounded-bl-lg' : '',
              featureIdx === features.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'relative group bg-white mb-4',
              `${feature.has || showAll ? 'flex' : 'hidden sm:flex'}`
            )}
          >
            <div className="mr-2">
              <span
                className={`${feature.has ? 'bg-yellow-100 text-yellow-500' : 'bg-gray-100 text-gray-500'} rounded-lg inline-flex p-3 ring-4 ring-white`}
              >
                <FeatureIcon feature={feature.slug} className="h-6 w-6"/>
              </span>
            </div>
            <div>
              <h3 className="text-sm mt-1 font-medium mb-0">
                <Link to={`/features/${feature.slug}/`} className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {unslugify(feature.slug)}
                </Link>
              </h3>
              <p className="mt-0 text-xs text-gray-500 mr-4">
                {featureText(feature.slug, feature.has)}
              </p>
            </div>
            
          </div>
        ))}
      </div>
      {!showAll ? (
        <div className="block sm:hidden text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer"
          onClick={() => setShowAll(true)}
        >
          Show all possible features →
        </div>
      ) : ''}
    </React.Fragment>
  )
}

export default CommunityFeatures;

