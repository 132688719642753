import React from "react"
import { Link } from "gatsby"

// import "../styles/topics.css"

// link is either to topic, or optionally topic on that platform
const TopicCard = ({topic, platform, feature, countOverride, mini, hidden, hideImage}) => {
    const count = countOverride || topic.count;

    var link = `/topics/${topic.slug}/`;
    if (platform){
      link = `/topics/${topic.slug}/platform/${platform.slug}/`
    }
    // if (feature){ // dont have these pages anymore
    //   link = `/topics/${topic.slug}/feature/${feature.slug}/`
    // }
    
    return (
      <li key={topic.slug} className="relative" style={{
        'display': hidden ? 'none' : 'block'
      }}>
        <Link to={link}>
          {!hideImage ? (
            <div className={`${mini ? 'aspect-h-4' : 'aspect-h-4 sm:aspect-h-7'} group block w-full aspect-w-10 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-rose-500 overflow-hidden`}>
              <img src={topic.image} alt={`${topic.name} communities`}
                className={`object-cover filter grayscale-75 group-hover:grayscale-25`} />
              <button type="button" className="absolute inset-0 focus:outline-none">
                <span className="sr-only">View details for {topic.name}</span>
              </button>
            </div>
          ) : ''}
          <p className={`${count === 0 ? 'line-through opacity-50' : ''} ${mini ? 'text-md' : 'text-lg '} mt-1 block font-medium text-rose-500 truncate pointer-events-none`}>
            {topic.name}
          </p>
          <p className="block text-xs text-gray-500 pointer-events-none">
            <span>{count} </span>
            {feature && feature.prefix ? (
              <span>{feature.prefix} </span>
            ) : ''}
            {platform && platform.slug !== 'independent' ? (
              <span>{count === 1 ? platform.groupName : platform.groupNamePlural} </span>
            ) : (
              <span>{count === 1 ? 'community' : 'communities'} </span>
            )}
            <span>
              {topic.people ? `for ${topic.people}` : ''}
            </span>
          </p>
        </Link>
      </li>
    )

}

export default TopicCard;
