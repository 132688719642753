
import React, { useState } from "react"
import { PlusSmIcon } from '@heroicons/react/solid'
// import { Link } from "gatsby"

// import {
//   UserGroupIcon,
//   ClipboardListIcon,
//   TagIcon,
// } from '@heroicons/react/outline'

// draws a community's star rating and count
const CommunityStarRating = ({
  community, 
  starHeight, // defaults to 5
}) => {

  if (!community || community.reviewCount === null || community.reviewAvg === null){
    return ''
  }

  return (
    <div className="flex">
      {[1,2,3,4,5].filter(i => i <= parseInt(community.reviewAvg)).map((s, index) => (
        <svg key={s} xmlns="http://www.w3.org/2000/svg" className={`h-${starHeight || '5'} w-${starHeight || '5'} text-yellow-400`} fill="#fef3c7" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </svg>
      ))}
      {[1,2,3,4,5].filter(i => i > parseInt(community.reviewAvg)).map((s, index) => (
        <svg key={s} xmlns="http://www.w3.org/2000/svg" className={`h-${starHeight || '5'} w-${starHeight || '5'} text-gray-200`} fill="#fff" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </svg>
      ))}
      <span className={`font-semibold ml-1 ${parseInt(community.reviewCount) > 0 ? 'opacity-40' : 'opacity-20'}`}>({community.reviewCount})</span>
    </div>
  )

}


export default CommunityStarRating
